import React, { useEffect, useState } from "react";
import { Typography, Card, CardContent, Grid, Button } from "@mui/material";
import { motion } from "framer-motion";
import "./Blog.css";
import axios from "axios";

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      const response = await axios.get("https://api.iamrom.net/api/blogs");
      setPosts(response.data);
    };
    fetchPosts();
  }, []);

  const handlePostClick = (post) => {
    setSelectedPost(post);
  };

  const handleBackToPosts = () => {
    setSelectedPost(null);
  };

  return (
    <motion.div
      className="blog-page"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      {selectedPost ? (
        <div className="selected-post">
          <Button onClick={handleBackToPosts} className="back-button">
            Back to All Posts
          </Button>
          <Typography variant="h4" className="post-title">
            {selectedPost.title}
          </Typography>
          {selectedPost.tags && (
            <div className="tags-container">
              {selectedPost.tags.map((tag, index) => (
                <span key={index} className="post-tag">
                  {tag}
                </span>
              ))}
            </div>
          )}
          <div className="post-box">
            {selectedPost.image && (
              <img
                src={`https://api.iamrom.net/${selectedPost.image}`}
                alt={selectedPost.title}
                className="post-image"
              />
            )}
            {/* Displaying HTML content with proper spacing */}
            <Typography
              variant="body1"
              className="post-content"
              dangerouslySetInnerHTML={{ __html: selectedPost.content }}
            />
          </div>
        </div>
      ) : (
        <>
          <Typography variant="h4" className="page-title">
            Blog
          </Typography>
          <Grid container spacing={3} className="blog-container">
            {posts.map((post) => (
              <Grid item xs={12} sm={6} md={4} key={post._id}>
                <Card
                  className="blog-card"
                  onClick={() => handlePostClick(post)}
                >
                  <Grid container spacing={2} className="card-content">
                    {post.image && (
                      <Grid item xs={5}>
                        <img
                          src={`https://api.iamrom.net/${post.image}`}
                          alt={post.title}
                          className="post-thumbnail"
                        />
                      </Grid>
                    )}
                    <Grid item xs={post.image ? 7 : 12}>
                      <CardContent>
                        <Typography variant="h6" className="blog-title">
                          {post.title}
                        </Typography>
                        <div className="tags-container">
                          {post.tags &&
                            post.tags.map((tag, index) => (
                              <span key={index} className="post-tag">
                                {tag}
                              </span>
                            ))}
                        </div>
                        <Typography
                          variant="body2"
                          className="blog-content"
                          dangerouslySetInnerHTML={{
                            __html: post.content.substring(0, 100) + "...",
                          }}
                        />
                      </CardContent>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </motion.div>
  );
};

export default Blog;
