import React, { useState } from "react";
import { Typography, TextField, Button, CircularProgress } from "@mui/material";
import { motion } from "framer-motion";
import "./BlogPassword.css";

const BlogPassword = ({ onPasswordSubmit }) => {
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false); // Loading state

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    await onPasswordSubmit(password);
    setLoading(false); // End loading
  };

  return (
    <motion.div
      className="blog-password"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <Typography variant="h4" className="page-title">
        Enter Blog Password
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          className="password-input"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          disabled={loading} // Disable input during loading
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="submit-button"
          disabled={loading} // Disable button during loading
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : "Submit"}
        </Button>
      </form>
    </motion.div>
  );
};

export default BlogPassword;
