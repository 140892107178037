import React, { useState } from "react";
import {
  Typography,
  Box,
  Button,
  Divider,
  TextField,
  IconButton,
} from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import axios from "axios";
import { toast } from "react-hot-toast";
import "./ArtDetailModal.css";

const ArtDetailModal = ({
  art,
  onClose,
  artworks = [],
  currentArtIndex,
  setCurrentArtIndex,
}) => {
  const [showOrderForm, setShowOrderForm] = useState(false);
  const [orderDetails, setOrderDetails] = useState({
    customerName: "",
    customerEmail: "",
    address: "",
    phoneNumber: "",
  });
  const [loading, setLoading] = useState(false);

  // Get current art item based on currentArtIndex
  const artItem = artworks[currentArtIndex] || null;

  // Return null if no art is found
  if (!artItem) {
    return null;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOrderDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const handlePlaceOrder = async () => {
    const { customerName, customerEmail, address, phoneNumber } = orderDetails;

    // Check for required fields
    if (!customerName) {
      toast.error("Full Name is required.");
      return;
    }
    if (!customerEmail) {
      toast.error("Email is required.");
      return;
    }
    if (!address) {
      toast.error("Delivery Address is required.");
      return;
    }
    if (!phoneNumber) {
      toast.error("Phone Number is required.");
      return;
    }

    const orderData = {
      customerName,
      customerEmail,
      itemType: "art",
      itemId: artItem._id,
      itemTitle: artItem.title,
      totalAmount: artItem.price,
      address,
      phoneNumber,
    };

    try {
      setLoading(true); // Set loading to true when order is being placed

      const token = localStorage.getItem("token");

      await axios.post("https://api.iamrom.net/api/orders/create", orderData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      toast.success(
        "Order placed! Please check your email for further details."
      );
      onClose(); // Close the modal on successful order placement
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Order creation failed. Please try again.";
      console.error(
        "Order placement error details:",
        error.response?.data || error.message
      );
      toast.error(errorMessage);
    } finally {
      setLoading(false); // Reset loading state after attempt
    }
  };

  // Navigation handlers
  const handlePrevious = () => {
    setShowOrderForm(false);
    setCurrentArtIndex(
      (currentArtIndex - 1 + artworks.length) % artworks.length
    );
  };

  const handleNext = () => {
    setShowOrderForm(false);
    setCurrentArtIndex((currentArtIndex + 1) % artworks.length);
  };

  return (
    <Box className="art-detail-modal">
      <Box className="navigation-arrows">
        <IconButton onClick={handlePrevious} sx={{ color: "#fff" }}>
          <ArrowBack />
        </IconButton>
        <IconButton onClick={handleNext} sx={{ color: "#fff" }}>
          <ArrowForward />
        </IconButton>
      </Box>

      {!showOrderForm ? (
        <>
          <img
            className="art-image"
            src={`https://api.iamrom.net/${artItem.imageUrl}`}
            alt={artItem.title}
          />
          <Box className="art-info">
            <Typography variant="h5">{artItem.title}</Typography>
            <Box
              className="art-description"
              sx={{ maxHeight: 200, overflowY: "auto", paddingRight: 1 }}
            >
              <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
                {artItem.description}
              </Typography>
            </Box>
            <Typography variant="h6">${artItem.price}</Typography>
            <Divider />
            <div className="artmodule-buttons">
              <Button
                variant="contained"
                onClick={() => setShowOrderForm(true)}
              >
                Purchase
              </Button>
              <Button
                variant="outlined"
                onClick={onClose}
                style={{ marginTop: "10px" }}
              >
                Close
              </Button>
            </div>
          </Box>
        </>
      ) : (
        <Box className="order-form">
          <Typography variant="h5">Order Details</Typography>
          <TextField
            label="Full Name"
            name="customerName"
            fullWidth
            margin="normal"
            value={orderDetails.customerName}
            onChange={handleInputChange}
            InputLabelProps={{
              style: { color: "#fff" },
            }}
            InputProps={{
              style: { color: "#fff" },
            }}
          />
          <TextField
            label="Email"
            name="customerEmail"
            type="email"
            fullWidth
            margin="normal"
            value={orderDetails.customerEmail}
            onChange={handleInputChange}
            InputLabelProps={{
              style: { color: "#fff" },
            }}
            InputProps={{
              style: { color: "#fff" },
            }}
          />
          <TextField
            label="Delivery Address"
            name="address"
            fullWidth
            margin="normal"
            value={orderDetails.address}
            onChange={handleInputChange}
            InputLabelProps={{
              style: { color: "#fff" },
            }}
            InputProps={{
              style: { color: "#fff" },
            }}
          />
          <TextField
            label="Phone Number"
            name="phoneNumber"
            fullWidth
            margin="normal"
            value={orderDetails.phoneNumber}
            onChange={handleInputChange}
            InputLabelProps={{
              style: { color: "#fff" },
            }}
            InputProps={{
              style: { color: "#fff" },
            }}
          />
          <div className="artmodule-buttons">
            <Button
              variant="contained"
              onClick={handlePlaceOrder}
              disabled={loading}
            >
              {loading ? "Placing Order..." : "Place Order"}
            </Button>
            <Button
              variant="outlined"
              onClick={() => setShowOrderForm(false)}
              style={{ marginTop: "10px" }}
            >
              Back
            </Button>
          </div>
        </Box>
      )}
    </Box>
  );
};

export default ArtDetailModal;
