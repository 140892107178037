import React, { useEffect, useState } from "react";
import { Typography, Grid, Box, Modal } from "@mui/material";
import ArtDetailModal from "./ArtDetailModal";
import axios from "axios";
import "./ArtGallery.css";

const ArtGallery = () => {
  const [artworks, setArtworks] = useState([]);
  const [selectedArtIndex, setSelectedArtIndex] = useState(null);

  useEffect(() => {
    const fetchArtworks = async () => {
      try {
        const response = await axios.get("https://api.iamrom.net/api/artworks");
        setArtworks(response.data);
      } catch (error) {
        console.error("Failed to fetch artworks:", error);
      }
    };
    fetchArtworks();
  }, []);

  const handleArtClick = (index) => setSelectedArtIndex(index);
  const handleCloseModal = () => setSelectedArtIndex(null);

  return (
    <Box className="art-gallery">
      <Typography variant="h4" className="gallery-title">
        Art Gallery
      </Typography>
      <Grid container spacing={3}>
        {artworks.map((art, index) => (
          <Grid item xs={12} sm={6} md={4} key={art._id}>
            <Box
              className="art-thumbnail"
              style={{
                backgroundImage: `url(https://api.iamrom.net/${art.imageUrl})`,
              }}
              onClick={() => handleArtClick(index)}
            >
              <Typography className="art-title">{art.title}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      {selectedArtIndex !== null && (
        <Modal open={true} onClose={handleCloseModal}>
          <ArtDetailModal
            art={artworks[selectedArtIndex]}
            artworks={artworks}
            currentArtIndex={selectedArtIndex}
            setCurrentArtIndex={setSelectedArtIndex}
            onClose={handleCloseModal}
          />
        </Modal>
      )}
    </Box>
  );
};

export default ArtGallery;
