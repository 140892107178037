import React from "react";
import { Container, Grid, Typography, IconButton } from "@mui/material";
import { Facebook, Instagram, Twitter, YouTube } from "@mui/icons-material";
import { CIcon } from '@coreui/icons-react';
import { cibTiktok } from '@coreui/icons'
import { Link } from "react-router-dom";
import "./Footer.css";




const Footer = () => {
  return (
    <footer className="footer">
      <Container maxWidth="lg">
        <Grid container spacing={4} className="footer-grid">
          {/* Social Media Links */}
          <Grid item xs={12} md={4} className="footer-section">
            <Typography variant="h6" gutterBottom>
              Follow Us
            </Typography>
            <div className="social-icons">
              <IconButton aria-label="tiktok" className="social-icon">
                <Link to="https://www.tiktok.com/@romstudio12?lang=en">
              <CIcon icon={cibTiktok} /></Link>
              </IconButton>
              <IconButton aria-label="instagram" className="social-icon">
                <Link to="https://www.instagram.com/romstudio12/">
                  <Instagram />
                </Link>
              </IconButton>
              <IconButton aria-label="youtube" className="social-icon">
                <YouTube />
              </IconButton>
            </div>
          </Grid>

          {/* Quick Links */}
          <Grid item xs={12} md={4} className="footer-section">
            <Typography variant="h6" gutterBottom>
              Quick Links
            </Typography>
            <ul className="footer-links">
              <li>
                <Link to="/gallery" className="footer-link">
                  Gallery
                </Link>
              </li>
              <li>
                <Link to="/blog" className="footer-link">
                  Blog
                </Link>
              </li>
              <li>
                <Link to="/contact" className="footer-link">
                  Contact Us
                </Link>
              </li>
            </ul>
          </Grid>

          {/* Copyright Info */}
          <Grid item xs={12} md={4} className="footer-section">
            <Typography variant="h6" className="footer-title" gutterBottom>
              I AM ROM
            </Typography>
            <Typography variant="body2">
              © {new Date().getFullYear()} IAMROM. All rights reserved. Built by{" "}
              <Link to="https://www.asdesigns.pro">ASDESIGNS</Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
