import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Divider } from "@mui/material";
import axios from "axios";
import "./CreateBlogPost.css";

const CreateBlogPost = ({ selectedPost, setSelectedPost }) => {
  const [post, setPost] = useState({
    title: "",
    content: "",
    image: null,
    tags: "",
  });

  useEffect(() => {
    // If a post is selected, load it into the form; otherwise, reset the form
    if (selectedPost) {
      setPost({
        title: selectedPost.title,
        content: selectedPost.content,
        image: selectedPost.image,
        tags: selectedPost.tags ? selectedPost.tags.join(", ") : "",
      });
    } else {
      setPost({ title: "", content: "", image: null, tags: "" });
    }
  }, [selectedPost]);

  const handleInputChange = (e) =>
    setPost({ ...post, [e.target.name]: e.target.value });

  const handleFileChange = (e) =>
    setPost({ ...post, image: e.target.files[0] });

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("title", post.title);
    formData.append("content", post.content);
    formData.append("tags", post.tags);
    if (post.image) formData.append("image", post.image);

    try {
      if (selectedPost) {
        // Update existing post
        await axios.put(
          `https://api.iamrom.net/api/blogs/${selectedPost._id}`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        setSelectedPost(null); // Clear selected post after update
      } else {
        // Create new post
        await axios.post("https://api.iamrom.net/api/blogs/upload", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      }
      setPost({ title: "", content: "", image: null, tags: "" }); // Reset form
    } catch (error) {
      console.error("Failed to submit post", error);
    }
  };

  return (
    <div className="create-blog-post">
      <Typography variant="h5">
        {selectedPost ? "Edit Blog Post" : "Create Blog Post"}
      </Typography>
      <Divider className="divider" />
      <TextField
        label="Title"
        name="title"
        variant="outlined"
        fullWidth
        value={post.title}
        onChange={handleInputChange}
        className="input-field"
      />
      <TextField
        label="Content"
        name="content"
        variant="outlined"
        fullWidth
        multiline
        rows={4}
        value={post.content}
        onChange={handleInputChange}
        className="input-field"
      />
      <TextField
        label="Tags (comma separated)"
        name="tags"
        variant="outlined"
        fullWidth
        value={post.tags}
        onChange={handleInputChange}
        className="input-field"
      />
      <input type="file" onChange={handleFileChange} className="file-input" />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        className="submit-button"
      >
        {selectedPost ? "Update" : "Publish"}
      </Button>
    </div>
  );
};

export default CreateBlogPost;
