import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  TextField,
  List,
  ListItem,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { toast } from "react-hot-toast"; // Add the toast import
import "./GalleryUpload.css";

const GalleryUpload = () => {
  const [artworks, setArtworks] = useState([]);
  const token = localStorage.getItem("token");

  const [form, setForm] = useState({
    title: "",
    description: "",
    price: "",
    file: null,
  });
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    const fetchArtworks = async () => {
      try {
        const response = await axios.get("https://api.iamrom.net/api/artworks");
        setArtworks(response.data);
      } catch (error) {
        console.error("Failed to fetch artworks", error);
      }
    };
    fetchArtworks();
  }, []);

  const handleInputChange = (e) =>
    setForm({ ...form, [e.target.name]: e.target.value });
  const handleFileChange = (e) => setForm({ ...form, file: e.target.files[0] });

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("title", form.title);
    formData.append("description", form.description);
    formData.append("price", form.price);
    formData.append("image", form.file);

    try {
      setIsUploading(true); // Disable button during upload
      await axios.post("https://api.iamrom.net/api/artworks", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success("Artwork uploaded successfully!");

      // Refresh artworks list and reset the form
      const response = await axios.get("https://api.iamrom.net/api/artworks");
      setArtworks(response.data);
      setForm({ title: "", description: "", price: "", file: null });
    } catch (error) {
      console.error("Upload failed", error);
      toast.error("Upload failed, please try again.");
    } finally {
      setIsUploading(false); // Enable button after upload
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://api.iamrom.net/api/artworks/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success("Artwork deleted successfully!");
      setArtworks((prevArtworks) =>
        prevArtworks.filter((art) => art._id !== id)
      );
    } catch (error) {
      console.error("Failed to delete artwork", error);
      toast.error("Delete failed, please try again.");
    }
  };

  return (
    <div className="gallery-upload">
      <Typography variant="h4">Upload New Artwork</Typography>
      <TextField
        name="title"
        label="Title"
        value={form.title}
        onChange={handleInputChange}
        fullWidth
      />
      <TextField
        name="description"
        label="Description"
        value={form.description}
        onChange={handleInputChange}
        fullWidth
      />
      <TextField
        name="price"
        label="Price"
        value={form.price}
        onChange={handleInputChange}
        fullWidth
      />
      <input type="file" onChange={handleFileChange} />
      <Button
        onClick={handleUpload}
        variant="contained"
        disabled={isUploading || !form.file} // Disable button if uploading or no file selected
      >
        {isUploading ? "Uploading..." : "Upload"}
      </Button>

      <Typography variant="h5" style={{ marginTop: "20px" }}>
        Existing Artworks
      </Typography>
      <List>
        {artworks.map((art) => (
          <ListItem key={art._id}>
            {art.title} - ${art.price}
            <IconButton onClick={() => handleDelete(art._id)}>
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default GalleryUpload;
