import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate } from "react-router-dom";
import "./Navbar.css";

const Navbar = ({ isAuthenticated, onSignOut }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSignOut = () => {
    onSignOut();
    navigate("/login");
  };

  const drawer = (
    <div className="drawer-content">
      <List>
        <ListItem button component={Link} to="/" onClick={handleDrawerToggle}>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/gallery"
          onClick={handleDrawerToggle}
        >
          <ListItemText primary="Gallery" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/blog"
          onClick={handleDrawerToggle}
        >
          <ListItemText primary="Blog" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/contact"
          onClick={handleDrawerToggle}
        >
          <ListItemText primary="Contact" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/art-lessons"
          onClick={handleDrawerToggle}
        >
          <ListItemText primary="Art Lessons" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/order-status"
          onClick={handleDrawerToggle}
        >
          <ListItemText primary="Order Status" />
        </ListItem>
        {isAuthenticated ? (
          <ListItem
            button
            onClick={() => {
              handleSignOut();
              handleDrawerToggle();
            }}
          >
            <ListItemText primary="Sign Out" />
          </ListItem>
        ) : (
          <ListItem
            button
            component={Link}
            to="/admin"
            onClick={handleDrawerToggle}
          >
            <ListItemText primary="Admin" />
          </ListItem>
        )}
      </List>
    </div>
  );

  return (
    <>
      <AppBar position="sticky" className="navbar">
        <Toolbar className="navbar-toolbar">
          <Typography className="navbar-title">
            <Link to="/">I AM ROM</Link>
          </Typography>
          <div className="nav-links">
            <Button color="inherit" className="nav-button">
              <Link to="/" className="nav-link">
                Home
              </Link>
            </Button>
            <Button color="inherit" className="nav-button">
              <Link to="/gallery" className="nav-link">
                Gallery
              </Link>
            </Button>
            <Button color="inherit" className="nav-button">
              <Link to="/blog" className="nav-link">
                Blog
              </Link>
            </Button>
            <Button color="inherit" className="nav-button">
              <Link to="/contact" className="nav-link">
                Contact
              </Link>
            </Button>
            <Button color="inherit" className="nav-button">
              <Link to="/art-lessons" className="nav-link">
                Art Lessons
              </Link>
            </Button>
            <Button color="inherit" className="nav-button">
              <Link to="/order-status" className="nav-link">
                Order Status
              </Link>
            </Button>
            {isAuthenticated ? (
              <Button
                color="inherit"
                className="nav-button"
                onClick={handleSignOut}
              >
                Sign Out
              </Button>
            ) : (
              <Button color="inherit" className="nav-button">
                <Link to="/admin" className="nav-link">
                  Admin
                </Link>
              </Button>
            )}
          </div>
          <IconButton
            sx={{ display: { xs: "flex", md: "none" }, justifyContent: "left" }}
            edge="start"
            color="inherit"
            aria-label="menu"
            className="menu-icon"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="temporary"
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        classes={{ paper: "drawer-paper" }}
      >
        {drawer}
      </Drawer>
    </>
  );
};

export default Navbar;
