import React, { useState } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import "./Contact.css";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "", // New Name field
    email: "",
    phone: "",
    message: "",
    subscribe: false,
  });

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Send contact message
      await axios.post("https://api.iamrom.net/api/contact/contact", {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        message: formData.message,
      });

      // Subscribe to newsletter if checkbox is checked
      if (formData.subscribe) {
        await axios.post("https://api.iamrom.net/api/subscriptions/subscribe", {
          email: formData.email,
        });
      }

      setSubmitted(true);
      setError("");
    } catch (err) {
      setError("Failed to send message. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="contact-page">
      <motion.div
        className="form-container"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <h2 className="contact-heading">Get in Touch</h2>

        {!submitted ? (
          <form className="contact-form" onSubmit={handleSubmit}>
            {/* Name Field */}
            <motion.div
              className="form-field"
              whileHover={{ scale: 1.05 }}
              whileFocus={{ scale: 1.05 }}
            >
              <label>Name</label>
              <input
                type="text"
                name="name"
                required
                value={formData.name}
                onChange={handleChange}
              />
            </motion.div>

            {/* Email Field */}
            <motion.div
              className="form-field"
              whileHover={{ scale: 1.05 }}
              whileFocus={{ scale: 1.05 }}
            >
              <label>Email</label>
              <input
                type="email"
                name="email"
                required
                value={formData.email}
                onChange={handleChange}
              />
            </motion.div>

            {/* Phone Field */}
            <motion.div
              className="form-field"
              whileHover={{ scale: 1.05 }}
              whileFocus={{ scale: 1.05 }}
            >
              <label>Phone (optional)</label>
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </motion.div>

            {/* Message Field */}
            <motion.div
              className="form-field"
              whileHover={{ scale: 1.05 }}
              whileFocus={{ scale: 1.05 }}
            >
              <label>Message</label>
              <textarea
                name="message"
                required
                value={formData.message}
                onChange={handleChange}
              />
            </motion.div>

            {/* Subscribe Checkbox */}
            <div className="subscribe-option">
              <input
                type="checkbox"
                name="subscribe"
                checked={formData.subscribe}
                onChange={handleChange}
              />
              <label>Subscribe to our newsletter</label>
            </div>

            {/* Submit Button */}
            <motion.button
              className="submit-button"
              type="submit"
              whileHover={{ scale: loading ? 1 : 1.05 }}
              whileTap={{ scale: loading ? 1 : 0.95 }}
              disabled={loading}
            >
              {loading ? "Sending..." : "Send Message"}
            </motion.button>

            {error && <p className="error-message">{error}</p>}
          </form>
        ) : (
          <motion.div
            className="confirmation-message"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h3>Thank you for reaching out!</h3>
            <p>We've received your message and will get back to you soon.</p>
          </motion.div>
        )}
      </motion.div>
    </div>
  );
};

export default Contact;
