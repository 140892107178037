import React, { useState, useEffect } from "react";
import {
  Typography,
  List,
  ListItem,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import "./OrderManagement.css";

const OrderManagement = () => {
  const [orders, setOrders] = useState([]);
  const [loadingOrderId, setLoadingOrderId] = useState(null);
  const [cancellationDialogOpen, setCancellationDialogOpen] = useState(false);
  const [cancellationReason, setCancellationReason] = useState("");
  const [currentOrderId, setCurrentOrderId] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("https://api.iamrom.net/api/orders", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setOrders(
          response.data.map((order) => ({ ...order, deliveryCost: "" }))
        );
      } catch (error) {
        console.error("Fetching orders failed", error);
      }
    };
    fetchOrders();
  }, []);

  const handleAddDeliveryCost = async (orderId, deliveryCost) => {
    setLoadingOrderId(orderId);
    try {
      const token = localStorage.getItem("token");
      await axios.put(
        `https://api.iamrom.net/api/orders/${orderId}/status`,
        { deliveryCost, status: "Awaiting Payment" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setOrders((orders) =>
        orders.map((order) =>
          order._id === orderId
            ? { ...order, deliveryCost, status: "Awaiting Payment" }
            : order
        )
      );
      toast.success("Invoice sent");
    } catch (error) {
      console.error("Adding delivery cost failed", error);
    } finally {
      setLoadingOrderId(null);
    }
  };

  const handleCancelOrder = async () => {
    if (!cancellationReason.trim()) return;
    try {
      const token = localStorage.getItem("token");
      await axios.put(
        `https://api.iamrom.net/api/orders/${currentOrderId}/status`,
        { status: "Cancelled", cancellationReason },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setOrders((orders) =>
        orders.map((order) =>
          order._id === currentOrderId
            ? { ...order, status: "Cancelled" }
            : order
        )
      );
    } catch (error) {
      console.error("Order cancellation failed", error);
    } finally {
      setCancellationDialogOpen(false);
      setCancellationReason("");
      setCurrentOrderId(null);
    }
  };

  const handleDispatchOrder = async (orderId) => {
    try {
      const token = localStorage.getItem("token");
      await axios.put(
        `https://api.iamrom.net/api/orders/${orderId}/status`,
        { status: "Dispatched" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setOrders((orders) =>
        orders.map((order) =>
          order._id === orderId ? { ...order, status: "Dispatched" } : order
        )
      );
    } catch (error) {
      console.error("Marking order as dispatched failed", error);
    }
  };

  const handleDeliverOrder = async (orderId) => {
    try {
      const token = localStorage.getItem("token");
      await axios.put(
        `https://api.iamrom.net/api/orders/${orderId}/status`,
        { status: "Delivered" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setOrders((orders) =>
        orders.map((order) =>
          order._id === orderId ? { ...order, status: "Delivered" } : order
        )
      );
    } catch (error) {
      console.error("Marking order as delivered failed", error);
    }
  };

  const activeOrders = orders.filter(
    (order) => order.status !== "Delivered" && order.status !== "Cancelled"
  );
  const completedOrders = orders.filter(
    (order) => order.status === "Delivered" || order.status === "Cancelled"
  );

  return (
    <div className="order-management">
      <Toaster />
      <Typography variant="h4">Order Management</Typography>

      {/* Active Orders Section */}
      <Typography variant="h5">Active Orders</Typography>
      <List>
        {activeOrders.map((order) => (
          <ListItem key={order._id} className="order-item">
            <div>
              <Typography variant="body1">
                <strong>{order.customerName}</strong> - {order.itemTitle}
              </Typography>
              <Typography variant="body2" className="order-status">
                <strong>Phone:</strong> {order.customerPhone}
              </Typography>
              <Typography variant="body2" className="order-status">
                <strong>Address:</strong> {order.deliveryAddress}
              </Typography>
              <Typography
                variant="body2"
                className="order-status"
                color="textSecondary"
              >
                Status: {order.status}
              </Typography>
            </div>
            {order.status === "Paid" ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleDispatchOrder(order._id)}
              >
                Mark as Dispatched
              </Button>
            ) : order.status === "Dispatched" ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleDeliverOrder(order._id)}
              >
                Mark as Delivered
              </Button>
            ) : (
              <>
                <TextField
                  label="Delivery Cost"
                  value={order.deliveryCost}
                  onChange={(e) =>
                    setOrders((orders) =>
                      orders.map((o) =>
                        o._id === order._id
                          ? { ...o, deliveryCost: e.target.value }
                          : o
                      )
                    )
                  }
                  sx={{ width: "150px", marginRight: "10px" }}
                  disabled={order.status === "Awaiting Payment"}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    handleAddDeliveryCost(order._id, order.deliveryCost)
                  }
                  disabled={
                    !order.deliveryCost ||
                    order.status === "Cancelled" ||
                    loadingOrderId === order._id ||
                    order.status === "Awaiting Payment"
                  }
                  sx={{ marginRight: "10px" }}
                >
                  {loadingOrderId === order._id
                    ? "Sending Invoice..."
                    : "Add Delivery Cost"}
                </Button>
              </>
            )}
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                setCurrentOrderId(order._id);
                setCancellationDialogOpen(true);
              }}
              disabled={order.status === "Cancelled"}
            >
              Cancel Order
            </Button>
          </ListItem>
        ))}
      </List>

      {/* Completed Orders Section */}
      <Typography variant="h5" sx={{ marginTop: "20px" }}>
        Completed Orders
      </Typography>
      <List>
        {completedOrders.map((order) => (
          <ListItem key={order._id} className="order-item">
            <div>
              <Typography variant="body1">
                <strong>{order.customerName}</strong> - {order.itemTitle}
              </Typography>
              <Typography
                variant="body2"
                className="order-status"
                color="textSecondary"
              >
                Status: {order.status}
              </Typography>
            </div>
          </ListItem>
        ))}
      </List>

      {/* Dialog for cancellation reason */}
      <Dialog
        open={cancellationDialogOpen}
        onClose={() => setCancellationDialogOpen(false)}
      >
        <DialogTitle>Cancel Order</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Cancellation Reason"
            fullWidth
            multiline
            value={cancellationReason}
            onChange={(e) => setCancellationReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setCancellationDialogOpen(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={handleCancelOrder} color="error">
            Confirm Cancellation
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OrderManagement;
