import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import {
  Button,
  Typography,
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import CreateBlogPost from "./CreateBlogPost";
import GalleryUpload from "./GalleryUpload";
import OrderManagement from "./OrderManagement";
import "./AdminDashboard.css";

const AdminDashboard = () => {
  const [activeSection, setActiveSection] = useState("blog");
  const [posts, setPosts] = useState([]); // Stores all blog posts
  const [selectedPost, setSelectedPost] = useState(null); // Stores currently selected post for editing

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get("https://api.iamrom.net/api/blogs");
        setPosts(response.data);
      } catch (error) {
        console.error("Failed to fetch posts", error);
      }
    };
    fetchPosts();
  }, []);

  const handleEditPost = (post) => {
    setSelectedPost(post); // Set the post to be edited
    setActiveSection("blog"); // Ensure we are on the blog section
  };

  const handleDeletePost = async (postId) => {
    try {
      await axios.delete(`https://api.iamrom.net/api/blogs/${postId}`);
      setPosts(posts.filter((post) => post._id !== postId)); // Update the list to remove deleted post
    } catch (error) {
      console.error("Failed to delete post", error);
    }
  };

  const handleUpdatePosts = (updatedPost) => {
    // Update post list after edit or new post is added
    setPosts((prevPosts) => {
      const index = prevPosts.findIndex((post) => post._id === updatedPost._id);
      if (index !== -1) {
        // Update existing post
        const newPosts = [...prevPosts];
        newPosts[index] = updatedPost;
        return newPosts;
      } else {
        // Add new post
        return [updatedPost, ...prevPosts];
      }
    });
    setSelectedPost(null); // Reset to create mode
  };

  const handleCancelEdit = () => {
    setSelectedPost(null); // Clear selected post to switch back to create mode
  };

  return (
    <Box className="admin-dashboard">
      <motion.div
        className="admin-header"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <Typography variant="h4" className="admin-title">
          Admin Dashboard
        </Typography>
        <Divider className="divider" />
      </motion.div>

      <Box className="admin-nav">
        <Button
          variant="contained"
          onClick={() => setActiveSection("blog")}
          className={activeSection === "blog" ? "active" : ""}
        >
          Manage Blog
        </Button>
        <Button
          variant="contained"
          onClick={() => setActiveSection("gallery")}
          className={activeSection === "gallery" ? "active" : ""}
        >
          Gallery Uploads
        </Button>
        <Button
          variant="contained"
          onClick={() => setActiveSection("orders")}
          className={activeSection === "orders" ? "active" : ""}
        >
          Orders Admin
        </Button>
      </Box>

      <Box className="admin-content">
        {activeSection === "blog" && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <Typography variant="h5">
              {selectedPost ? "Edit Blog Post" : "Create Blog Post"}
            </Typography>
            <CreateBlogPost
              selectedPost={selectedPost}
              setSelectedPost={setSelectedPost}
              onCancelEdit={handleCancelEdit}
              onUpdatePosts={handleUpdatePosts}
            />
            <Divider className="divider" />
            <Typography variant="h6" className="posts-list-title">
              Blog Posts
            </Typography>
            <List className="posts-list">
              {posts.map((post) => (
                <ListItem
                  key={post._id}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleDeletePost(post._id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary={post.title}
                    onClick={() => handleEditPost(post)}
                  />
                </ListItem>
              ))}
            </List>
          </motion.div>
        )}
        {activeSection === "gallery" && <GalleryUpload />}
        {activeSection === "orders" && <OrderManagement />}
      </Box>
    </Box>
  );
};

export default AdminDashboard;
