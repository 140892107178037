import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import AdminDashboard from "./pages/AdminDashboard";
import Blog from "./pages/Blog";
import BlogPassword from "./pages/BlogPassword";
import Login from "./pages/Login";
import ArtGallery from "./pages/ArtGallery";
import OrderTracking from "./pages/OrderTracking";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Loader from "./components/Loader";
import { Toaster, toast } from "react-hot-toast";
import axios from "axios";

const theme = createTheme({
  palette: { primary: { main: "#00adb5" }, secondary: { main: "#ff4081" } },
  typography: { fontFamily: '"MaximaNouva","Wosker","Mayor"' },
});

const getToken = () => localStorage.getItem("token");

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = !!getToken();
  return isAuthenticated ? children : <Navigate to="/login" />;
};

const MainContent = () => {
  const [loading, setLoading] = useState(false);
  const [isBlogAccessGranted, setIsBlogAccessGranted] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(!!getToken());
  const location = useLocation();
  const navigate = useNavigate();

  // Handle admin sign out
  const handleSignOut = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    navigate("/login");
  };

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => setLoading(false), 1500); // Reduced delay
    return () => clearTimeout(timer);
  }, [location]);

  const handlePasswordSubmit = async (password) => {
    try {
      const response = await axios.post(
        "https://api.iamrom.net/api/blogs/validate-password",
        { password }
      );
      if (response.status === 200) {
        setIsBlogAccessGranted(true);
        toast.success("Access granted!");
        navigate("/blog");
      }
    } catch (error) {
      toast.error("Invalid password");
      setIsBlogAccessGranted(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <>
          <Navbar isAuthenticated={isAuthenticated} onSignOut={handleSignOut} />
          <Toaster position="top-center" />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/gallery" element={<ArtGallery />} />
            <Route path="/order-status" element={<OrderTracking />} />
            <Route
              path="/blog"
              element={
                isBlogAccessGranted ? (
                  <Blog />
                ) : (
                  <Navigate to="/blog-password" />
                )
              }
            />
            <Route
              path="/blog-password"
              element={<BlogPassword onPasswordSubmit={handlePasswordSubmit} />}
            />
            <Route
              path="/login"
              element={<Login onLogin={() => setIsAuthenticated(true)} />}
            />
            <Route
              path="/admin"
              element={
                <ProtectedRoute>
                  <AdminDashboard />
                </ProtectedRoute>
              }
            />
          </Routes>
          <Footer />
        </>
      )}
    </>
  );
};

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <MainContent />
      </Router>
    </ThemeProvider>
  );
};

export default App;
