import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Grid,
  Button,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import anime from "animejs";
import { motion } from "framer-motion";
import Logo from "../images/logo.png";
import Rom from "../images/1.jpeg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./Home.css";

const Home = () => {
  const [artworks, setArtworks] = useState([]);
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Scroll-triggered animations using Anime.js
    anime({
      targets: ".about-section",
      opacity: [0, 1],
      translateX: [-100, 0],
      easing: "easeInOutQuad",
      duration: 1000,
      delay: 200,
    });

    anime({
      targets: ".blog-section",
      opacity: [0, 1],
      translateY: [100, 0],
      easing: "easeInOutQuad",
      duration: 1000,
      delay: 300,
    });

    anime({
      targets: ".latest-artwork-section",
      opacity: [0, 1],
      translateX: [100, 0],
      easing: "easeInOutQuad",
      duration: 1000,
      delay: 400,
    });

    const fetchArtworks = async () => {
      const response = await axios.get("https://api.iamrom.net/api/artworks");
      setArtworks(response.data.slice(0, 3)); // Get the latest 3 artworks
    };

    const fetchPosts = async () => {
      const response = await axios.get("https://api.iamrom.net/api/blogs");
      setPosts(response.data.slice(0, 3)); // Get the latest 3 blog posts
    };

    fetchArtworks();
    fetchPosts();
  }, []);

  const handleBlogClick = () => {
    navigate("/blog-password"); // Redirect to blog password page
  };

  return (
    <Container maxWidth="lg">
      {/* Introduction Section */}
      <motion.section
        className="intro-section"
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1, ease: "easeInOut", delay: 0.5 }}
      >
        <img src={Logo} alt="Logo" className="intro-logo" />
        <Typography variant="h2" align="center" gutterBottom>
          Welcome to ROM's World
        </Typography>
        <Typography variant="body1" align="center">
          Discover, explore, and experience the creative works of ROM.
        </Typography>
      </motion.section>

      {/* About Section */}
      <section className="about-section">
        <Typography variant="h4" gutterBottom>
          About ROM
        </Typography>
        <div className="about-text">
          <img src={Rom} alt="Rom" className="about-img" />
          <Typography
            variant="p"
            sx={{ fontSize: { xs: "1.4rem", md: "2rem" } }}
          >
            ROM Madina (Rebecca Obiageli Madu) is a visual artist from Nigeria,
            whose work navigates the transformative journey of identity,
            empowerment, and resilience. ROM used art as a medium for healing
            from childhood trauma and self-discovery. Inspired by the emotional
            depth of Vincent van Gogh and her own personal battles, ROM’s
            creations—particularly the My Lady Series—explore women’s strength
            in adversity. Through her vibrant, symbolic art, ROM advocates for
            empowerment, elevating the voices and experiences of women
            worldwide.
          </Typography>
        </div>
      </section>

      {/* Latest Artwork Section */}
      <section className="latest-artwork-section">
        <Typography variant="h4" gutterBottom>
          Latest Artworks
        </Typography>
        <Grid container spacing={3}>
          {artworks.map((art, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <Card>
                {/* Display thumbnail as artwork image */}
                <CardMedia
                  component="img"
                  image={`https://api.iamrom.net/${art.imageUrl}`}
                  alt={art.title}
                  height="200"
                />
              </Card>
            </Grid>
          ))}
          {/* Fill remaining spaces with placeholders if less than 3 artworks */}
          {artworks.length < 3 &&
            Array.from({ length: 3 - artworks.length }).map((_, index) => (
              <Grid item xs={12} sm={4} key={`placeholder-${index}`}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">
                      More content coming soon
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Grid>
        <Button
          variant="contained"
          color="primary"
          href="/gallery"
          sx={{ marginTop: 2 }}
        >
          View Full Gallery
        </Button>
      </section>

      {/* Blog Section */}
      <section className="blog-section">
        <Typography variant="h4" gutterBottom>
          Latest Blog Posts
        </Typography>
        <Grid container spacing={3}>
          {posts.map((post, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <Card onClick={handleBlogClick} style={{ cursor: "pointer" }}>
                <CardContent>
                  <Typography variant="h6">{post.title}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
          {/* Fill remaining spaces with placeholders if less than 3 posts */}
          {posts.length < 3 &&
            Array.from({ length: 3 - posts.length }).map((_, index) => (
              <Grid item xs={12} sm={4} key={`placeholder-${index}`}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">
                      More content coming soon
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Grid>
        <Button
          variant="contained"
          color="primary"
          onClick={handleBlogClick}
          sx={{ marginTop: 2 }}
        >
          View More Blog Posts
        </Button>
      </section>
    </Container>
  );
};

export default Home;
