// components/OrderTracking.jsx
import React, { useState } from "react";
import { Typography, TextField, Button, Box } from "@mui/material";
import axios from "axios";
import "./OrderTracking.css";

const OrderTracking = () => {
  const [orderId, setOrderId] = useState("");
  const [orderDetails, setOrderDetails] = useState(null);

  const handleTrackOrder = async () => {
    try {
      const response = await axios.get(
        `https://api.iamrom.net/api/orders/track/${orderId}`
      );
      setOrderDetails(response.data);
    } catch (error) {
      console.error("Failed to fetch order details", error);
    }
  };

  return (
    <Box className="order-tracking">
      <Typography variant="h4">Track Your Order</Typography>
      <TextField
        label="Order ID"
        value={orderId}
        onChange={(e) => setOrderId(e.target.value)}
      />
      <Button variant="contained" onClick={handleTrackOrder}>
        Track Order
      </Button>
      {orderDetails && (
        <Box className="order-details">
          <Typography variant="h6">Order ID: {orderDetails.orderId}</Typography>
          <Typography>Status: {orderDetails.status}</Typography>
          <Typography>Customer Name: {orderDetails.customerName}</Typography>
          <Typography>
            Delivery Address: {orderDetails.deliveryAddress}
          </Typography>
          <Box
            className="order-image"
            style={{ backgroundImage: `url(${orderDetails.itemImageUrl})` }}
          />
        </Box>
      )}
    </Box>
  );
};

export default OrderTracking;
