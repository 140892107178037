import React, { useState } from "react";
import { Typography, TextField, Button } from "@mui/material";
import { motion } from "framer-motion";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./Login.css";

const Login = () => {
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const response = await axios.post(
        "https://api.iamrom.net/api/admin/login",
        credentials
      );
      localStorage.setItem("token", response.data.token); // Store token in local storage
      navigate("/admin"); // Redirect to the admin dashboard
    } catch (err) {
      setError("Invalid username or password");
    }
  };

  return (
    <motion.div
      className="login-page"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <Typography variant="h4" className="login-title">
        Admin Login
      </Typography>
      <form onSubmit={handleSubmit} className="login-form">
        <TextField
          label="Username"
          name="username"
          variant="outlined"
          className="input-field"
          value={credentials.username}
          onChange={handleInputChange}
        />
        <TextField
          label="Password"
          name="password"
          type="password"
          variant="outlined"
          className="input-field"
          value={credentials.password}
          onChange={handleInputChange}
        />
        {error && (
          <Typography color="error" className="error-message">
            {error}
          </Typography>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="login-button"
        >
          Login
        </Button>
      </form>
    </motion.div>
  );
};

export default Login;
